<template>
  <v-dialog
    v-model="localLoading"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text class="pt-3">
        Please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import { ref, watch } from 'vue'

export default {
    name: 'PleaseStandBy',
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const localLoading = ref(props.loading)

        watch(() => props.loading, newVal => {
            localLoading.value = newVal
        })

        return {
            localLoading
        }
    },
}
</script>
